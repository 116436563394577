@import '../../styles/theme';

.main {
  padding: 32px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.main nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.mainDark {
  background: $background--dark;
  color: $brand--light;
}

.mainDark .navLink {
  color: $brand--light;
}

.mainLight .navLink {
  color: $brand--dark;
}

.mainLight {
  background: $background--light;
}

.main h1 {
  text-decoration: none !important;
  border: none;
}

.navLink, p, h1 {
  margin: 0px 24px;
  font-family: 'IBM Plex Mono', monospace;
}

.navLink {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}

.navLink:hover {
  text-decoration: underline;
}

.mainDark .callToAction {
  --borderWidth: 1;
  --boxShadowDepth: 4;
  --buttonColor: white;
  --fontSize: 3;
  --horizontalPadding: 16;
  --verticalPadding: 8;

  margin: 0px 12px;

  background: transparent;
  border: calc(var(--borderWidth) * 1px) solid var(--buttonColor);
  box-shadow: calc(var(--boxShadowDepth) * 1px) calc(var(--boxShadowDepth) * 1px) 0 #888;
  color: var(--buttonColor);
  cursor: pointer;
  outline: transparent;
  padding: calc(var(--verticalPadding) * 1px) calc(var(--horizontalPadding) * 1px);
  transition: all $animation--fast ease;
}

.mainLight .callToAction {
  --borderWidth: 1;
  --boxShadowDepth: 4;
  --buttonColor: black;
  --fontSize: 3;
  --horizontalPadding: 16;
  --verticalPadding: 8;

  margin: 0px 12px;

  background: transparent;
  border: calc(var(--borderWidth) * 1px) solid var(--buttonColor);
  box-shadow: calc(var(--boxShadowDepth) * 1px) calc(var(--boxShadowDepth) * 1px) 0 #888;
  color: var(--buttonColor);
  cursor: pointer;
  outline: transparent;
  padding: calc(var(--verticalPadding) * 1px) calc(var(--horizontalPadding) * 1px);
  transition: all $animation--fast ease;
}

.main button {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}

.callToAction:hover {
  box-shadow: calc(var(--boxShadowDepth) / 2 * 1px) calc(var(--boxShadowDepth) / 2 * 1px) 0 #888;
}

.callToAction:active {
  box-shadow: 0 0 0 #888;
}

.brand {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.mainDark a:visited {
  color: $brand--light;
}

.mainLight a:visited {
  color: $brand--dark;
}

.mainDark .logo {
  background: url('../assets/logo--white.svg') transparent no-repeat;
}

.mainLight .logo {
  background: url('../assets/logo--colors.svg') transparent no-repeat; 
}

.logo {
  background-size: 100%;
  width: 63px;
  height: 36px;
  padding: 0px;
  margin: 0px;
}


h1 {
  font-size: 36px;
  font-weight: bold;
}

@media (max-width: 700px) {
  .main {
    flex-direction: column;
  }

  .main nav {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 24px;
  }

  .main .navLink {
    margin-bottom: 18px;
  }
}