@import '../../../styles/theme';

.servicesContent {
  
}

.contact button {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 24px;
  margin: 48px;
  --borderWidth: 1;
  --boxShadowDepth: 4;
  --buttonColor: black;
  --fontSize: 3;
  --horizontalPadding: 36;
  --verticalPadding: 12;

  background: transparent;
  border: calc(var(--borderWidth) * 1px) solid var(--buttonColor);
  box-shadow: calc(var(--boxShadowDepth) * 1px) calc(var(--boxShadowDepth) * 1px) 0 #888;
  color: var(--buttonColor);
  cursor: pointer;
  outline: transparent;
  padding: calc(var(--verticalPadding) * 1px) calc(var(--horizontalPadding) * 1px);
  transition: all $animation--fast ease;
}

.contact button:hover {
  box-shadow: calc(var(--boxShadowDepth) / 2 * 1px) calc(var(--boxShadowDepth) / 2 * 1px) 0 #888;
}

.contact button:active {
  box-shadow: 0 0 0 #888;
}