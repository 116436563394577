@import '../../../../styles/theme';

.valuesContent {
  width: 80%;
  margin: 0px auto;
  margin-top: 200px;
}

.valuesContent h1 {
  font-size: 64px;
  margin: 0px;
  margin-bottom: 48px;
}

.valuesContent h1 > span {
  @include magicUnderline($background--yellow)
}
