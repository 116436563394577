@import '../../../../styles/theme';

.areasContent {
  width: 80%;
  margin: 0px auto;
  margin-bottom: 100px;
  margin-top: 100px;
}

.areasContent h1 {
  font-size: 64px;
  margin: 0px;
  margin-bottom: 48px;
}

.areasContent h1 > span {
  @include magicUnderline($background--light-blue)
}
