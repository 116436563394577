//== Colors
$font--dark: #3e3e3e;
$font--light: #fff;

// Backgrounds
$background--dark: #000;
$background--light: #fff;

$background--yellow: #E8CB71;
$background--blue: #3C6C9C;
$background--silver: #D0D0D0;
$background--light-blue: #B4D4FD;
$background--light-orange: #FFC198;

// Brand Colors
$brand--light: #fff;
$brand--dark: #000;
$brand--red: #EA4244;
$brand--yellow: #E8CB71;
$brand--blue: #3C6C9C;
$brand--light-red: #ffcccb;