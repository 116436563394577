.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:0px;
  margin-top: 0px;
}

.mainWrapper > h3 {
  font-size: 32px;
  margin-left: 32px;
}

.tileContent {
  width: 50%;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  display: block;
  position: relative;
}

.tileBody {
  opacity: 0;
  background-color: rgba(white, 0);
  transition: opacity 0.4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.tileBody img {
  margin: 0px auto;
  display: block;
  margin-top: 32px;
  margin-bottom: 32px;
  max-height: 120px;
  max-width: 220px;
}

.tileContent:hover .backgroundImage, .tileContent:active .backgroundImage {
  scale: 1.1 1.1;
  -webkit-transform:scale(1.1);
  opacity: 0.05;
}

.tileContent:hover .tileBody, .tileContent:active .tileBody {
  opacity: 1.0;
}

.tileBody {
  font-size: 16px;
  line-height: 36px;
  font-family: sans-serif;
}

.tileMainText {
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.tileBody p {
  margin: 18px;

}

.backgroundImage {
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

@media (max-width: 1050px ) {
  .tileContent {
    width: 100%;
  }

  .workContent > h2 {
    font-size: 18px;
    padding-top: 24px;
    margin-left: 12px;
  }
}