@import '../../../../styles/theme';

.quotesContent {
  margin: 300px 0px 100px 0px;
  padding: 48px;
  background-color: $brand--dark;
  color: $font--light;
  @include angle(after, true, 5deg);
  @include angle(before, false, 5deg);
  padding-bottom: 0px;
}

.quotesContent > * {
  width: 80%;
  margin: 0px auto;
}

.quotesContent > h2 {
  margin-bottom: 48px;
}

.quotesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 96px;
}

.quote {
  line-height: 2em;
  font-size: 22px;
  background: url('../../../assets/quote.svg') transparent no-repeat;
  background-position: top left;
  padding: 12px 36px;
  background-color: transparent;
}

.quotePerson > img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.quotePerson {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;
  color: lightgrey;
  margin-right: 96px; 
  margin-top: 48px;
}

@media (max-width: 700px) {
  .quotesContent {
    padding: 12px;
    margin-top: 100px;
    padding-top: 48px;
  }

  .quotesContent > * {
    padding: 0px;
  }

  .quote {
    font-size: 16px;
    line-height: 1.5em;
    padding: 12px 0px 0px 36px;
  }

  .quotePerson {
    margin-right: 12px;
    margin-top: 48px;
  }
}
