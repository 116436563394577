@import '../../../../styles/theme';

.aboutContent {
  background-color: $background--light;
  background: url('../../../assets/logo--large.svg') transparent no-repeat;
  background-position: center right;
  text-align: left;
  padding: 300px 10% 120px 10%;
  margin: 24px;
}

.aboutContent h2, .aboutContet p {
  padding: 0px;
  margin: 0px;
  color: $brand--dark;
}

.aboutContent p {
  font-size: 22px;
  line-height: 2em;
  margin-top: 44px;
}

.aboutContent h2 {
  font-size: 42px;
}

.designFocused {
  
}

.technicalChops {
  @include magicUnderline($brand--red);
}

@media (max-width: 700px) {
  .aboutContent {
    margin: 0px;
    padding: 100px 12px;
  }

  .aboutContent h2 {
    font-size: 24px;
  }

  .aboutContent p {
    font-size: 16px;
  }
}