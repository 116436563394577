@import '../../../../styles/theme';

.peopleContent {

  padding: 0px 96px;
}

.peopleContent h2 {
  font-size: 36px;
  margin-bottom: 48px;
}

.peopleContent h2 > span {
  @include magicUnderline($brand--yellow);
}

.personContent {
  display: flex;
  flex-direction: row;
  align-items: top;
  margin-bottom: 100px;
  margin-left: 48px;
}

.personImage p {
  margin: 0px;
  padding: 0px;
  margin-top: 12px;
  margin-bottom: 24px;
}

.personImage .title {
  font-weight: bold;
}

.personContent img {
  height: 200;
  width: 200px;
  box-shadow: 4px 4px black;
  border: 1px solid black;
}

.personText p {
  margin-bottom: 24px;
  margin-top: 0px;
  padding: 0px;
  font-size: 16px;
  color: #3E3E3E;
  line-height: 1.5em;
}

@media (max-width: 700px) {

  .peopleContent {
    padding: 12px;
  }

  .personContent {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 0px;
    margin-bottom: 48px;
    padding: 12px;
  }

  .personText p {
    margin: 12px 0px;
    padding: 0px;
    font-size: 16px;
    color: #3E3E3E;
    line-height: 1.5em;
  }

  .personImage p {
    font-size: 18px;
  }
}