@import '../../../../../styles/theme';

.stepViewContent {
  display: flex;
  flex-direction: row;
  align-items: top;
  flex-flow: flex-start;
  margin: 0px auto;
  margin-top: 48px;
  width: 80%;
  line-height: 2em;
}

.stepViewContent h3 {
  color: $font--light;
  margin: 24px 0px 0px 48px;
  font-size: 24px;
}

.stepViewContent h3 > span {
  // @include magicUnderline($brand--red);
}

.stepImage {
  width: 25%;
  height: 25%;
  flex: 0 0 auto;
}

.stepText {
  display: flex;
  flex-direction: column;
  flex-flow: flex-start;
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .stepViewContent {
    flex-direction: column;
    align-items: center;
  }

  .stepImage {
    width: 75%;
    height: 75%;
    flex: 0 0 auto;
  }

  .stepViewContent h3 {
    margin: 24px 0px 0px 0px;
  }
}