@import '../../../../../styles/theme';

.areaTableContent {
  margin: 100px 48px 48px 48px;
}

.areaTableContent h2 {
  font-size: 32px;
}

.areaTableContent h2 > span {
  @include magicUnderline($brand--yellow)
}

.serviceContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  padding: 10px;
}

.service {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid lightgray;
  padding: 24px;
  width: 300px;
  margin-top: -1px;
  margin-left: -1px;
}

.service img {
  width: 44px;
  height: 44px;
}

.service h3 {
  margin-left: 24px;
}

@media (max-width: 700px) {
  .areaTableContent {
    margin: 48px 0px 0px 0px;
  }

  .service {
    padding: 12px;
  }

  .service h3 {
    margin-left: 18px;
    font-size: 16px;
  }

  .service img {
    width: 32px;
    height: 32px;
  }
}
