@import '../../../../styles/theme';

.banner {
  background-color: $background--dark;
  color: $brand--light !important;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  @include angle(after, true, 5deg);
}

.banner p {
  font-size: 28px;
  margin: 0px auto;
  padding: 48px;
  padding-top: 120px;
  line-height: 2;
  text-align: center;
  color: $brand--light;
  font-family: 'IBM Plex Mono', monospace;
  order: 0;
  margin-bottom: -100px;
  z-index: 1;
}

.thinkSpan {
  
}

.designers, .engineers, .aiSpecialists {
  font-weight: bold;
}

.downArrow {
  position: relative;
  top: 0;
  margin: 0px auto;
  width: 24px;
  height: 24px;
  background: url('../../../assets/down-arrow.svg') transparent no-repeat;
  background-size: 100%;
  order: 2;
}

.tagline {
  
}

.image {
  order: 1;
  margin-top: 48px;
}

.hands_1 {
  background: url('../../../assets/hands_1.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

.hands_2 {
  background: url('../../../assets/hands_2.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

.hands_3 {
  background: url('../../../assets/hands_3.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

.hands_4 {
  background: url('../../../assets/hands_4.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

.hands_5 {
  background: url('../../../assets/hands_5.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

.hands_6 {
  background: url('../../../assets/hands_6.png') transparent no-repeat;
  background-size: 100%;
  height: 35vw;
  background-position: center;
}

@media (max-width: 700px) {
  .banner p {
    padding: 12px;
    order: 1;
    margin-bottom: 48px;
    font-size: 22px;
  }

  .downArrow {
    display: none;
  }

  .image {
    order: 0;
    margin-top: 0px;
  }
}