@import '../../../../styles/theme';

.statsContent {
  font-family: 'IBM Plex Mono', monospace;
  width: 80%;
  margin: 0px auto;
  margin-top: 100px;
}

.descriptor {
  line-height: 2em;
  margin-left: 96px;
  margin-right: 96px;
  margin-bottom: 36px;
  margin-top: 96px;
  font-size: 24px;
  text-align: center;
}

.descriptorPunchline {
  font-size: 32px;
  line-height: 2em;
}

.statsContent h1 {
  padding: 0px;
  margin: 0px;
}

.secondSynapse {
  font-weight: bold !important;
  font-size: 64px;
  margin-bottom: 100px;
}

.secondSynapse span {
  @include magicUnderline($brand--red);
  margin: 0px;
  padding: 0px;
}

.statsContent ul {
  padding: 62px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.statsContent li {
  display: flex;
  flex-direction: row;
  wrap: wrap;
  align-items: baseline;
  font-size: 24px;
  margin: 0px;
  margin-left: -1px;
  margin-top: -1px;
  padding: 24px;
  border: 1px solid black;
}

.statsContent h2 {
  font-weight: normal;
  font-size: 24px;
  margin: 0px;
  margin-right: 4px;
  padding: 0px;
}

.statsContent li > p {
  font-weight: bold;
  margin: 0px;
}

.learnButton:hover {
  box-shadow: calc(var(--boxShadowDepth) / 2 * 1px) calc(var(--boxShadowDepth) / 2 * 1px) 0 #888;
}

.learnButton:active {
  box-shadow: 0 0 0 #888;
}

@media (max-width: 700px) {
  .statsContent h1 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .descriptor {
    font-size: 18px;
    line-height: 2em;
    margin: 0px;
    padding: 18px;
  }

  .statsContent ul {
    flex-direction: column;
    padding: 0px;
    margin: 0px auto;
    margin-top: 48px;
  }
}