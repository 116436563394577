$animation--fast: 0.3s;
$animation--medium: 1s;
$animation--slow: 1.5s;

.fadeIn {
  animation: fadeIn ease $animation--slow;
  -webkit-animation: fadeIn ease $animation--slow;
  -moz-animation: fadeIn ease $animation--slow;
  -o-animation: fadeIn ease $animation--slow;
  -ms-animation: fadeIn ease $animation--slow;
}

@keyframes fadeIn {
  0% {
    opacity:0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}