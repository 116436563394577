@import '../../../../styles/theme';

.methodologyContent {
  margin: 200px 0px 100px 0px;
  padding: 48px;
  background-color: $brand--blue;
  color: $font--light;
  @include angle(after, true, 5deg);
  @include angle(before, false, 5deg);
}

.methodologyContent h2 {
  text-transform: uppercase;
  width: 80%;
  margin: 0px auto;
  padding: 0px;
}

.methodologyContent p {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0px;
}

.methodologyContent p > span {
  font-weight: bold; 
}

@media (max-width: 700px) {
  .methodologyContent {
    padding: 12px;
  }

  .methodologyContent h2 {
    margin-top: 48px;
  }

  .methodologyContent p {
    margin: 0px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0px;
  }
}