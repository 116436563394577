@import '../../../../styles/theme';

.iconWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.clientIcon {
  margin: 24px 56px;
  opacity: 0.3;
  max-height: 76px;
}

@media (max-width: 700px) {
  .clientIcon {
    max-height: 38px;
    max-width: 30%;
    margin: 12px 24px;
  }
}